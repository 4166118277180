var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board"},[(_vm.portraitMode)?_c('turn-your-screen'):[(_vm.displayRules)?_c('rules',{on:{"close":_vm.HIDE_RULES,"play":_vm.HIDE_RULES}}):_vm._e(),(_vm.showResultDialog)?_c('info-overlay',{attrs:{"title":_vm.winnerScreen.title},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('mb-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.resetBoard(_vm.startPlayer)}}},[_vm._v("Restart")]),_c('mb-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.showResultDialog = false}}},[_vm._v("See game")])]},proxy:true}],null,false,3613063037)},[_vm._v(" "+_vm._s(_vm.winnerScreen.message)+" ")]):_vm._e(),(_vm.showStartScreen)?_c('info-overlay',{attrs:{"title":_vm.startScreen.title},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('mb-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showStartScreen = false}}},[_vm._v("Go")])]},proxy:true}],null,false,932612580)},[_vm._v(" "+_vm._s(_vm.startScreen.message)+" ")]):_vm._e(),(!_vm.showResultDialog && _vm.connectionRequiresAttention)?_c('info-overlay',{attrs:{"title":_vm.connectionStatus,"try-again-func":_vm.retry}},[_vm._v(" "+_vm._s(_vm.connectionMessage)+" ")]):_vm._e(),_c('nav-bar',{staticClass:"nav-bar",attrs:{"collapse":!_vm.largeScreen}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"grid"},[_c('laser-emitter',{staticClass:"emitter"}),_c('laser-receiver',{staticClass:"player-a",attrs:{"light-on":_vm.playerAlost,"primaryColor":_vm.playersColor.b}}),_c('laser-receiver',{staticClass:"player-b",attrs:{"light-on":_vm.playerBlost,"primaryColor":_vm.playersColor.a}}),_vm._l((_vm.positions),function(line,i){return _vm._l((line),function(square,j){return _c('div',{key:("square_(" + i + "," + j + ")"),staticClass:"grid__square",class:{
              'grid__square--highlighted': (i === _vm.selectedI) && (j === _vm.selectedJ),
              'grid__square--selectable': square.type === null,
              'wall__north': square.walls.includes('north'),
              'wall__east': square.walls.includes('east') || (j === _vm.map.width - 1),
              'wall__south': square.walls.includes('south') || (i === _vm.map.height - 1),
              'wall__west': square.walls.includes('west'),
            },on:{"click":function($event){return _vm.selectSquare(i, j)}}},[_c('transition',{attrs:{"name":"destroyed"}},[(square.type)?_c(square.type,{tag:"component",class:square.direction,attrs:{"color":_vm.playersColor[square.player],"size":_vm.pieceSize}}):_vm._e()],1)],1)})}),_vm._l((_vm.beamsWithData),function(beamData,index){return _c('div',{key:index,staticClass:"laser-beam",style:(beamData)})})],2),_c('pieces-drawer',{staticClass:"pieces",attrs:{"player":_vm.currentPlayer,"ready":_vm.showPiecesDrawer,"size":_vm.pieceSize},on:{"change":function($event){return _vm.updateSquare(_vm.selectedI, _vm.selectedJ, $event[0], $event[1])}}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }