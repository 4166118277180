<template>
  <div class="setup-page">
    <span class="back" @click="$router.back()">
      <i class="el-icon-top"></i><span>Back</span>
    </span>
    <h1 class="title">
      {{ title }}
    </h1>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;
@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

.setup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #86BBD8;
  padding: 16px;
  .title {
    margin: 16px 0;
  }
  .back {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 16px;
    margin-left: 16px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-color: $charcoal;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3.33rem;
    & > * {
      margin: 4px 0;
    }
    @include media-min(768px) {
      flex-direction: row;
      & > * {
        margin: 0 4px;
      }
    }
  }
}
</style>
