<template>
  <div class="pieces-drawer">
    <div class="pieces-drawer__player-label"
      :class="{'pieces-drawer__player-label--disabled': disabled}"
      :style="{backgroundColor: playerColor}">
      {{ playersBiGram[player] }}
    </div>
    <div class="selector" v-if="!pieceType">
        <div class="piece"
          :class="{'piece--thinking': remotePlayerThinking && thinkingIndex === index}"
          :disabled="globalDisable || playerRemainingPiecesFormatted[t].disabled"
          v-for="t, index in ['stopper', 'mirror', 'spreader']"
          :key="t"
          @click="selectType(t)">
          <mb-badge
            :value="playerRemainingPiecesFormatted[t].remaining"
            :class="{'el-badge--infinity': playerRemainingPiecesFormatted[t].infinity}"
            :color="playerColor">
            <component :is="t" :size="pieceSize" />
          </mb-badge>
        </div>
        <div class="piece"
          :class="{'piece--thinking': remotePlayerThinking && thinkingIndex === 3}"
          :disabled="globalDisable || playerRemainingPiecesFormatted['super-spreader'].disabled"
          @click="selectType('super-spreader')">
          <mb-badge
            :class="{
              'el-badge--infinity': playerRemainingPiecesFormatted['super-spreader'].infinity
            }"
            :value="playerRemainingPiecesFormatted['super-spreader'].remaining"
            :color="playerColor">
            <super-spreader :size="pieceSize" />
          </mb-badge>
        </div>
    </div>
    <div class="selector" v-else>
      <div class="piece"
        v-for="dir in ['north', 'east', 'south', 'west']"
        :key="dir"
        @click="change([pieceType, dir])">
        <component :is="pieceType" :size="pieceSize" :class="dir"/>
      </div>
    </div>
    <div class="info">
      <span v-if="!ready && !disabled && !remotePlayerThinking"
        :style="{color: playerColor}">Select a square</span>
      <span v-if="remotePlayerThinking"
        :style="{color: playerColor}">Thinking...</span>
      <mb-button
        v-if="ready && pieceType"
        class="back-button"
        @click="pieceType = null"
        icon="el-icon-back"
        type="secondary" size="mini">Back</mb-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import mode from '../helpers/playModes';
import Stopper from './Stopper.vue';
import Mirror from './Mirror.vue';
import Spreader from './Spreader.vue';
import SuperSpreader from './SuperSpreader.vue';
import MbBadge from './library/MbBadge.vue';
import MbButton from './library/MbButton.vue';

export default {
  components: {
    Stopper,
    Mirror,
    Spreader,
    SuperSpreader,
    MbBadge,
    MbButton,
  },
  props: {
    player: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: 'default',
      validator: (v) => 'default|large'.split('|').includes(v),
    },
  },
  data() {
    return {
      pieceType: null,
      direction: null,
      thinkingInterval: null,
      thinkingIndex: 0,
    };
  },
  computed: {
    ...mapState(['playMode', 'localPlayer']),
    ...mapGetters(['playerRemainingPieces', 'playersColor', 'playersBiGram']),
    playerRemainingPiecesFormatted() {
      return {
        stopper: this.formatPieceNumber(this.remainingPieces.stopper),
        mirror: this.formatPieceNumber(this.remainingPieces.mirror),
        spreader: this.formatPieceNumber(this.remainingPieces.spreader),
        'super-spreader': this.formatPieceNumber(this.remainingPieces['super-spreader']),
      };
    },
    globalDisable() {
      return this.disabled || !this.ready || this.remotePlayerThinking;
    },
    remotePlayerThinking() {
      return ([mode.ONLINE, mode.AI].includes(this.playMode) && (this.localPlayer !== this.player));
    },
    remainingPieces() {
      return this.playerRemainingPieces(this.player);
    },
    playerColor() {
      return this.playersColor[this.player];
    },
    pieceSize() {
      return this.size === 'large' ? 'default' : 'mini';
    },
  },
  methods: {
    formatPieceNumber(n) {
      if (n === 'infinity') {
        return {
          remaining: '∞',
          disabled: false,
          infinity: true,
        };
      }
      return {
        remaining: n,
        disabled: n <= 0,
        infinity: false,
      };
    },
    selectType(t) {
      if (this.globalDisable || this.playerRemainingPiecesFormatted[t].disabled) {
        return;
      } if (t === 'super-spreader') {
        this.change(['super-spreader', 'any']);
        return;
      }
      this.pieceType = t;
    },
    change(payload) {
      this.$emit('change', payload);
      this.pieceType = null;
      this.direction = null;
    },
    updateThinkingIndex() {
      this.thinkingIndex = (this.thinkingIndex + 1) % 4;
    },
  },
  watch: {
    remotePlayerThinking() {
      if (this.thinkingInterval) {
        clearInterval(this.thinkingInterval);
      }
      if (!this.remotePlayerThinking) {
        return;
      }
      this.thinkingInterval = setInterval(this.updateThinkingIndex, 250);
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

.pieces-drawer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  height: 100%;
  .pieces-drawer__player-label {
    height: 50px;
    width: 50px;
    font-weight: bold;
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
    &.pieces-drawer__player-label--disabled {
      background-color: lightgray !important;
      color: gray;
    }
  }
  .info {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
    padding-left: 8px;
    padding-right: 8px;
    span {
      font-size: 12px;
      font-weight: bold;
    }
    .back-button {
      background-color: $dark-sky-blue;
    }
  }
  .selector {
    display:  grid;
    grid-template-rows: repeat(4, 1fr);
    height: min(100%, calc(7 * 50px));
    width: min(100%, 87px);
    .piece {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      padding: 12px 0;
      svg {
        line, circle, rect {
          transition: stroke 250ms;
        }
      }
      .el-badge__content {
        transition: background-color 250ms;
      }
      &:hover {
        background-color: lightgray;
      }
      .north {
      transform: rotate(0deg);
      }
      .east {
        transform: rotate(90deg);
      }
      .south {
        transform: rotate(180deg);
      }
      .west {
        transform: rotate(270deg);
      }
      &[disabled] {
        cursor: auto;
        &:hover{
          background-color: inherit;
        }
        svg {
          line, circle, rect {
            stroke: lightgray;
          }
        }
        .el-badge__content {
          background-color: lightgray !important;
        }
      }
      &.piece--thinking {
        svg {
          line, circle, rect {
            stroke: silver;
          }
        }
        .el-badge__content {
          background-color: silver !important;
        }
      }
      .el-badge--infinity {
        .el-badge__content {
          font-size: 20px;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
