<template>
  <setup-page class="online-create" title="Create new game">
    <div class="online-create__content">
      <div>
        <span class="attribute-name">Name</span>
        <mb-input class="code" v-model="name" placeholder="Enter your name here..."/>
      </div>
      <mb-button class="start-button" icon="el-icon-plus"
        :disabled="!name" @click="goToWaitRoom">Create game</mb-button>
    </div>
  </setup-page>
</template>

<script>
import { mapActions } from 'vuex';
import SetupPage from '../components/SetupPage.vue';
import MbButton from '../components/library/MbButton.vue';
import MbInput from '../components/library/MbInput.vue';

export default {
  components: {
    SetupPage,
    MbButton,
    MbInput,
  },
  data() {
    return {
      name: localStorage.getItem('onlineName'),
    };
  },
  methods: {
    ...mapActions(['createOnlineGame']),
    generateRandomChar() {
      return String.fromCharCode(97 + Math.floor(Math.random() * 26));
    },
    goToWaitRoom() {
      localStorage.setItem('onlineName', this.name);
      this.createOnlineGame({
        name: this.name,
        code: this.code,
      });
      this.$router.push({ name: 'WaitingRoom' });
    },
  },
  computed: {
    code() {
      return 'xxx-xxx-xxx'.split('').map((c) => (c === 'x' ? this.generateRandomChar() : c)).join('');
    },
  },
};
</script>

<style lang="scss">
.online-create {
  .online-create__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin: 4px 0;
    }
    .start-button {
      margin: 16px 0;
    }
    .share-link > *{
      margin: 4px;
    }
    .attribute-name {
      font-weight: bold;
      margin-right: 4px;
    }
  }
  .code {
    width: 15em;
  }
}
</style>
