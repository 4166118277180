<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="pxWidth" :height="pxWidth" version="1.1">
      <circle :cx="pxWidth/2" :cy="pxWidth/2"
        :r="pxWidth/2-2" :stroke="color" stroke-width="2" fill="white"/>
  </svg>
</template>

<script>
import PieceMixin from './PieceMixin';

export default {
  mixins: [PieceMixin],
};
</script>
