<template>
  <setup-page :title="connectionMessage" class="waiting-room">
    <div v-if="isHost && connectionOk" class="share">
      <span class="attribute-name">Share this link
        <mb-button icon="el-icon-copy-document" size="mini"
          @click="copySuccessful = $refs.code.copyToClipboard()"/>
      </span>
      <span class="share-link">
        <mb-input ref="code" class="code" :value="`mirror-battle.de/${gameCode}`"/>
      </span>
      <span class="feedback" :class="{'feedback--visible': copySuccessful}">
        <i class="el-icon-check"></i>
        Copied to clipboard
      </span>
    </div>
    <div class="join" v-if="!isHost && connectionOk">
      <div>
        <span class="attribute-name">Name</span>
        <mb-input class="code" v-model="name" placeholder="Enter your name here..."/>
      </div>
      <mb-button class="start-button" icon="el-icon-arrow-right"
        :disabled="!name" @click="join">Join game</mb-button>
    </div>
  </setup-page>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import status from '../helpers/connectionStatuses';
import SetupPage from '../components/SetupPage.vue';
import MbButton from '../components/library/MbButton.vue';
import MbInput from '../components/library/MbInput.vue';

export default {
  components: {
    SetupPage,
    MbButton,
    MbInput,
  },
  created() {
    this.$amplitude.logEvent('WaitingRoom');
  },
  data() {
    return {
      copySuccessful: false,
      name: localStorage.getItem('onlineName'),
    };
  },
  computed: {
    ...mapGetters(['isHost']),
    ...mapState(['connectionStatus', 'connectionMessage', 'gameCode']),
    title() {
      if (this.isHost) {
        return 'Waiting for someone to join.';
      }
      return 'Connecting to the game...';
    },
    connectionOk() {
      return this.connectionStatus !== status.ERROR;
    },
  },
  methods: {
    ...mapActions(['joiningPlayerReady']),
    join() {
      localStorage.setItem('onlineName', this.name);
      this.joiningPlayerReady(this.name);
      this.$router.push({ name: 'OnlineGame', params: { code: this.gameCode } });
    },
  },
  watch: {
    connectionStatus() {
      if (this.connectionStatus === status.SUCCESS && this.isHost) {
        this.$amplitude.logEvent('OnlineGamePlayersConnected');
        this.$router.push({ name: 'OnlineGame', params: { code: this.gameCode } });
      }
    },
  },
};
</script>

<style lang="scss">
  .waiting-room {
    .content {
      display: flex;
      flex-direction: column;
      .share {
        margin-top: 8px;
        .attribute-name {
          font-weight: bold;
        }
        > * {
          margin: 4px;
        }
        .share-link {
          display: flex;
          flex-direction: row;
          > * {
            margin: 4px;
          }
          .code {
            width: 15em;
          }
        }
        .feedback {
          font-size: 12px;
          visibility: hidden;
          &.feedback--visible {
            visibility: visible;
          }
        }
      }
      .join {
        .attribute-name {
          font-weight: bold;
        }
        > * {
           margin: 4px;
        }
      }
    }
  }
</style>
