export default {
  props: {
    color: {
      type: String,
      default: 'gray',
    },
    size: {
      type: String,
      default: 'default',
      validator: (value) => 'default|mini|large'.split('|').includes(value),
    },
  },
  computed: {
    pxWidth() {
      if (this.size === 'mini') {
        return 30;
      } if (this.size === 'large') {
        return 75;
      }
      return 50;
    },
  },
};
