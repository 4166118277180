<template>
  <!-- eslint-disable max-len-->
  <svg xmlns="http://www.w3.org/2000/svg" :height="pxWidth" :width="pxWidth" version="1.1">
    <!-- https://stackoverflow.com/questions/1734745/how-to-create-circle-with-b%C3%A9zier-curves -->
    <path :d="`M1 0 V ${pxWidth} H ${pxWidth} V -${pxWidth} L ${pxWidth-1} 0 C ${pxWidth} ${pxWidth*0.62}, 0 ${pxWidth*0.62}, 1 0 Z`" fill="white"/>
    <line x1="1" x2="1" y1="0" :y2="pxWidth" :stroke="color" stroke-width="2" stroke-dasharray="4"/>
    <line x1="0" :x2="pxWidth" :y1="pxWidth-1" :y2="pxWidth-1" :stroke="color"
      stroke-width="2" stroke-dasharray="4"/>
    <line :x1="pxWidth-1" :x2="pxWidth-1" :y1="pxWidth" y2="0" :stroke="color" stroke-width="2"
      stroke-dasharray="4"/>
    <circle
      :cx="pxWidth/2" cy="0" :r="pxWidth/2-1"
      :stroke="color" stroke-width="2" fill="none"/>
  </svg>
</template>

<script>
import PieceMixin from './PieceMixin';

export default {
  mixins: [PieceMixin],
};
</script>
