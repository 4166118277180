<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="pxWidth" :height="pxWidth" version="1.1">
     <g>
        <circle :cx="pxWidth/2" :cy="pxWidth/2"
          :r="pxWidth/2-1" :stroke="color" stroke-width="2" fill="white"/>
        <rect x="0" :y="pxWidth/2" :width="pxWidth"
          :height="pxWidth" fill="white" stroke-width="0"/>
     </g>
     <line x1="0" :x2="pxWidth" :y1="pxWidth-1" :y2="pxWidth-1" :stroke="color" stroke-width="2"
      stroke-dasharray="4" />
     <line x1="1" x2="1" :y1="pxWidth/2"
      :y2="pxWidth" :stroke="color" stroke-width="2"/>
     <line :x1="pxWidth-1" :x2="pxWidth-1" :y1="pxWidth/2"
      :y2="pxWidth" :stroke="color" stroke-width="2"/>
  </svg>
</template>

<script>
import PieceMixin from './PieceMixin';

export default {
  mixins: [PieceMixin],
};
</script>
