<template>
  <el-input
    ref="input"
    :type="type"
    class="mb-input" :value="value" @input="$emit('input', $event)"
    :placeholder="placeholder" :readonly="readonly" :autosize="autosize">
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script>
export default {
  props: ['placeholder', 'value', 'readonly', 'type', 'autosize'],
  methods: {
    copyToClipboard() {
      this.$refs.input.select();
      document.execCommand('copy');
      return true;
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

.mb-input {
  border: none;
  border-bottom: 1px solid $charcoal;
  input, textarea {
    font-family: inherit;
    border: none;
    color: $charcoal;
    background-color: $dark-sky-blue;
    &::placeholder {
      color: rgba(51,101,138, 0.5);
    }
  }
}
</style>
