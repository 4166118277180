<template>
  <setup-page class="launch-game" title="Play with friends">
    <mb-button icon="el-icon-upload"
      @click="newOnline">Online</mb-button>
    <mb-button icon="el-icon-s-flag"
      type="secondary"
      @click="newOffline">On this device</mb-button>
  </setup-page>
</template>

<script>
import MbButton from '../components/library/MbButton.vue';
import SetupPage from '../components/SetupPage.vue';

export default {
  components: {
    MbButton,
    SetupPage,
  },
  methods: {
    newOnline() {
      this.$amplitude.logEvent('ClickedPlayOnline');
      this.$router.push({ name: 'NewOnline' });
    },
    newOffline() {
      this.$amplitude.logEvent('ClickedPlayOffline');
      this.$router.push({ name: 'NewOffline' });
    },
  },
};
</script>
