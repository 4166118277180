<template>
  <setup-page class="online-join" title="Join existing game">
    <div class="online-join__content">
      <div>
        <span>Game code</span>
        <span class="share-link">
          <mb-input class="code" v-model="code" placeholder="xxx-xxx-xxx"/>
        </span>
      </div>
      <mb-button class="start-button"
        icon="el-icon-connection" :disabled="!codeIsValid"
        @click="goToWaitRoom(code)">Connect</mb-button>
    </div>
  </setup-page>
</template>

<script>
import { mapActions } from 'vuex';
import SetupPage from '../components/SetupPage.vue';
import MbButton from '../components/library/MbButton.vue';
import MbInput from '../components/library/MbInput.vue';

export default {
  components: {
    SetupPage,
    MbInput,
    MbButton,
  },
  data() {
    return {
      code: null,
      connexionOk: false,
    };
  },
  computed: {
    codeIsValid() {
      if (!this.code) { return false; }
      return this.code.match(/^[a-z]{3}-[a-z]{3}-[a-z]{3}$/) !== null;
    },
  },
  methods: {
    ...mapActions(['joinOnlineGame']),
    goToWaitRoom(code) {
      this.joinOnlineGame(code);
      this.$router.push({ name: 'WaitingRoom' });
    },
  },
};
</script>

<style lang="scss">
.online-join {
  .online-join__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .start-button {
      margin: 16px 0;
    }
    .share-link > *{
      margin: 4px;
    }
  }
  .code {
    width: 15em;
  }
}
</style>
