<template>
  <div class="rules-container">
    <div class="rules__header">
      How to play?
      <div class="close" @click="close">
        &times;
      </div>
    </div>
    <div class="rules">
      <div class="rule">
        <img
          class="rule__image"
          src="../assets/rules__goal.png">
        <div class="rule__explanation">
          <div class="explanation__title">
            Goal: be the first to light up your laser receiver
          </div>
          <div class="explanation__message">
            Turn by turn, each player will place mirrors on the board to be the first to
            light up the laser receiver bearing their color. Players also often try to complicate
            the other player task by placing defending mirrors next to the other receiver.
          </div>
        </div>
      </div>
      <div class="rule">
        <div class="rule__explanation" style="width: 100%;">
          <div class="explanation__title" style="text-align: right;">
            Use 4 different of types of mirrors and place them in any direction
          </div>
          <div class="explanation__message" style="text-align: right;">
            <div class="types-of-mirrors">
              <div class="type">Stopper</div>
              <div class="explanation">Stop the laser beam</div>
              <div class="type">Mirror</div>
              <div class="explanation">Deflect the laser beam by 45deg</div>
              <div class="type">Spreader</div>
              <div class="explanation">Create two beams from one beam</div>
              <div class="type">Super-speader</div>
              <div class="explanation">Create three beams from one beam</div>
            </div>
          </div>
        </div>
        <img
          class="rule__image"
          src="../assets/rules__pieces.png">
      </div>
      <div class="rule">
        <img
          class="rule__image"
          src="../assets/rules__destroy.png">
        <div class="rule__explanation">
          <div class="explanation__title">
            Destroy pieces by directing a laser at their back
          </div>
          <div class="explanation__message">
            Pieces can be destroyed if they are hit by a laser on a non-reflective
            part (marked by a dashed line). This means that all pieces already
            on the board can potentially be destroyed, expect for Super-spreaders.
          </div>
        </div>
      </div>
      <div class="rule">
        <div class="rule__explanation" style="width: 100%;">
          <div class="explanation__title" style="text-align: right;">
            Forbidden moves
          </div>
          <div class="explanation__message" style="text-align: right;">
            <div>
              Placing mirrors back to back
              <span style="font-size: 12px">(=sticking together non-reflective parts)
              </span>
            </div>
            <div>Obstructing the laser emitter with a stopper</div>
            <div>Blocking a laser receiver with a spreader</div>
          </div>
        </div>
        <img
          class="rule__image"
          src="../assets/rules__forbidden.png">
      </div>
      <mb-button class="btn-play" @click="play">Play</mb-button>
    </div>
  </div>
</template>

<script>
import MbButton from './library/MbButton.vue';

export default {
  components: {
    MbButton,
  },
  created() {
    this.$amplitude.logEvent('RulesDisplay');
  },
  methods: {
    close() {
      this.$amplitude.logEvent('RulesClose', { type: 'close' });
      this.$emit('close');
    },
    play() {
      this.$amplitude.logEvent('RulesClose', { type: 'play' });
      this.$emit('play');
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

$large-screen: 900px;

.rules-container {
  position: fixed;
  z-index: 2000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: $dark-sky-blue;
  color: $charcoal;
  .rules__header {
    height: 56px;
    font-size: 32px;
    padding: 8px;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      font-size: 32px;
      cursor: pointer;
      padding: 16px;
    }
  }
  .rules {
    height: calc(100vh - 48px);
    width: 100vw;
    overflow-y: scroll;
    padding: 12px;
    .rule {
      margin: 12px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      @include media-min($large-screen) {
        margin: 12px 20%;
      }
      .rule__image {
        height: 256px;
        width: 256px;
        background-color: gray;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 32px;
      }
      .rule__explanation {
        flex-grow: 1;
        padding: 12px 24px;
        display: flex;
        flex-direction: column;
        .explanation__title {
          font-size: 24px;
          font-weight: bold;
          text-align: start;
        }
        .explanation__message {
          margin-top: 8px;
          font-size: 16px;
          text-align: start;
        }
      }
      .types-of-mirrors {
        display: grid;
        grid-template-columns: auto fit-content(320px);
        grid-column-gap: 16px;
        .type {
          text-align: right;
          font-weight: 500;
        }
        .explanation {
          text-align: left;
        }
      }
    }
    .btn-play {
      margin-bottom: 16px;
    }
  }
}
</style>
