<template>
  <div class="info-overlay">
    <div class="title" v-if="!sent">
      Talk to us
    </div>
    <div class="title" v-else>
      Thank you ✅
    </div>
    <div class="message" v-if="!sent">
      <div class="label">Email
          <span style="font-size: 10px;">
            [Optional] Only used to recontact you regarding your message.
          </span>
      </div>
      <mb-input v-model="email" />
      <div class="label">Feedback, suggestions, bugs, etc.</div>
      <mb-input type="textarea" v-model="content" :autosize="{ minRows: 2, maxRows: 5 }"/>
    </div>
    <div class="actions" v-if="!sent">
      <slot name="actions">
        <mb-button @click="sendFeedback">Send</mb-button>
        <mb-button
          type="secondary"
          @click="$emit('close')">
          Cancel
        </mb-button>
      </slot>
    </div>
    <div class="actions" style="margin-top: 16px;" v-else>
      <slot name="actions">
        <mb-button
          @click="$emit('close')">
          Continue
        </mb-button>
      </slot>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MbButton from './library/MbButton.vue';
import MbInput from './library/MbInput.vue';

export default {
  components: {
    MbButton,
    MbInput,
  },
  data() {
    return {
      email: null,
      content: null,
      sent: false,
    };
  },
  methods: {
    sendFeedback() {
      axios.post('https://emdjrbvwke.execute-api.eu-west-3.amazonaws.com/mirror-battle-feedback', {
        email: this.email,
        content: this.content,
      }).then(() => {
        this.$amplitude.logEvent('FeedbackSent');
        this.sent = true;
      }).catch((error) => {
        this.$amplitude.logEvent('FeedbackSendingError', {
          error,
        });
      });
    },
  },
  created() {
    this.$amplitude.logEvent('FeedbackFormOpened');
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

.info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1000;
  padding: 16px;
  .title {
    font-size: 32px;
    font-weight: bold;
  }
  .message {
    margin: 8px 0 16px 0;
    min-width: 400px;
    .label {
      margin: 8px 0;
      font-weight: 500;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    .mb-button {
      margin: 4px 0;
    }
    @include media-min(768px) {
      flex-direction: row;
      .mb-button {
        margin: 0 4px;
      }
    }
  }
}
</style>
