<template>
  <el-badge :value="value" ref="badge">
    <slot></slot>
  </el-badge>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#f56c6c',
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    updateColor() {
      this.$refs.badge.$el.getElementsByClassName('el-badge__content')[0].style.backgroundColor = this.color;
    },
  },
  watch: {
    color() {
      this.updateColor();
    },
  },
  mounted() {
    this.updateColor();
  },
};
</script>
