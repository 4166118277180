<template>
  <div class="launch-game">
    <span class="back" @click="$router.back()">
      <i class="el-icon-top"></i><span>Back</span>
    </span>
    <h1 class="title">
      Play on this device
    </h1>
    <div class="offline-mode">
      <div class="player">
        <div class="color-bubble left-player"></div>
        <span>Left player</span>
        <mb-input v-model="leftPlayerName" placeholder="Enter player name..."/>
      </div>
      <div class="player">
        <div class="color-bubble right-player"></div>
        <span>Right player</span>
        <mb-input v-model="rightPlayerName" placeholder="Enter player name..."/>
      </div>
      <mb-button icon="el-icon-arrow-right"
        style="margin-top: 32px;"
        @click="start"
        :disabled="!readyToMoveForward">Start game</mb-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MbButton from '../components/library/MbButton.vue';
import MbInput from '../components/library/MbInput.vue';

export default {
  components: {
    MbButton,
    MbInput,
  },
  data() {
    return {
      leftPlayerName: localStorage.getItem('deviceLeftPlayerName'),
      rightPlayerName: localStorage.getItem('deviceRightPlayerName'),
    };
  },
  computed: {
    readyToMoveForward() {
      return this.leftPlayerName && this.rightPlayerName;
    },
  },
  methods: {
    ...mapActions(['createOfflineGame']),
    start() {
      localStorage.setItem('deviceLeftPlayerName', this.leftPlayerName);
      localStorage.setItem('deviceRightPlayerName', this.rightPlayerName);
      this.createOfflineGame({
        playerA: this.leftPlayerName, playerB: this.rightPlayerName,
      });
      this.$amplitude.logEvent('StartOfflineGame');
      this.$router.push({ name: 'OfflineGame' });
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;
@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

.launch-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #86BBD8;
  .back {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 16px;
    margin-left: 16px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-color: $charcoal;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
  .offline-mode {
    width: 80vw;
    @include media-min(768px) {
      width: 350px;
    }
    .player {
      display: flex;
      align-items: center;
      justify-content: center;
      .color-bubble {
        flex: 0 0 16px;
        height: 16px;
        border-radius: 16px;
        &.left-player {
          background-color: $queen-blue;
        }
        &.right-player {
          background-color: $honey-yellow;
        }
      }
      span {
        flex: 1 0 100px;
        margin: 0 8px;
        font-weight: bold;
      }
    }
  }
}
</style>
