<template>
  <div class="info-overlay">
    <div class="title">
      {{ title }}
    </div>
    <div class="message">
      <slot></slot>
    </div>
    <div class="actions">
      <slot name="actions">
        <mb-button v-if="tryAgainFunc" @click="tryAgainFunc">Try again</mb-button>
        <mb-button
          :type="tryAgainFunc ? 'secondary': 'primary'"
          @click="$router.push({name: 'Home'})">
          Back home
        </mb-button>
      </slot>
    </div>
  </div>
</template>

<script>
import MbButton from './library/MbButton.vue';

export default {
  components: {
    MbButton,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    tryAgainFunc: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

.info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1000;
  padding: 16px;
  .title {
    font-size: 32px;
    font-weight: bold;
  }
  .message {
    margin: 16px 0;
  }
  .actions {
    display: flex;
    flex-direction: column;
    .mb-button {
      margin: 4px 0;
    }
    @include media-min(768px) {
      flex-direction: row;
      .mb-button {
        margin: 0 4px;
      }
    }
  }
}
</style>
