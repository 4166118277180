<template>
  <setup-page title="Play online">
    <mb-button icon="el-icon-plus"
      @click="onlineCreate">Create new game</mb-button>
    <mb-button icon="el-icon-connection"
      @click="onlineJoin" type="secondary">Join existing game</mb-button>
  </setup-page>
</template>

<script>
import SetupPage from '../components/SetupPage.vue';
import MbButton from '../components/library/MbButton.vue';

export default {
  components: {
    SetupPage,
    MbButton,
  },
  methods: {
    onlineCreate() {
      this.$amplitude.logEvent('ClickedOnlineCreate');
      this.$router.push({ name: 'OnlineCreate' });
    },
    onlineJoin() {
      this.$amplitude.logEvent('ClickedOnlineJoin');
      this.$router.push({ name: 'OnlineJoin' });
    },
  },
};
</script>
