<template>
  <div class="nav-bar">
    <div>
      <img src="../assets/mirror-battle-menu-icon.png"
        class="nav-bar__icon">
      <div class="nav-bar__title">Mirror Battle</div>
      <div class="nav-bar__group">
        <div class="nav-bar__item" @click="$router.push({name: 'Home'})">
          <div class="nav-bar-item__icon">
            <i class="el-icon-s-home"></i>
          </div>
          <div class="nav-bar-item__text">
            Home
          </div>
        </div>
        <div class="nav-bar__item" @click="triggerGoBack" :disabled="!goBackAllowed">
          <div class="nav-bar-item__icon">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="nav-bar-item__text">
            Undo
          </div>
        </div>
        <div class="nav-bar__item" @click="triggerGoForward" :disabled="!goForwardAllowed">
          <div class="nav-bar-item__icon">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="nav-bar-item__text">
            Redo
          </div>
        </div>
        <div class="nav-bar__item" @click="showRestartDialog = true">
          <div class="nav-bar-item__icon">
            <i class="el-icon-refresh-right"></i>
          </div>
          <div class="nav-bar-item__text">
            Restart game
          </div>
        </div>
      </div>
    </div>
    <div class="nav-bar__group">
      <div class="nav-bar__item" @click="showFeedbackForm = true">
        <div class="nav-bar-item__icon">
          <i class="el-icon-chat-dot-round"></i>
        </div>
        <div class="nav-bar-item__text">
          Talk to us
        </div>
      </div>
      <div class="nav-bar__item" @click="DISPLAY_RULES">
        <div class="nav-bar-item__icon">
          <i class="el-icon-question"></i>
        </div>
        <div class="nav-bar-item__text">
          Rules
        </div>
      </div>
    </div>
    <info-overlay v-if="showRestartDialog" title="Restart game?">
      You will lose your current game. Continue?
      <template v-slot:actions>
        <mb-button type="primary"
          @click="restart">Ok</mb-button>
        <mb-button type="secondary" @click="showRestartDialog = false">Cancel</mb-button>
      </template>
    </info-overlay>
    <feedback-form
      v-if="showFeedbackForm"
      @close="showFeedbackForm = false"/>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState, mapMutations,
} from 'vuex';
import InfoOverlay from './InfoOverlay.vue';
import FeedbackForm from './FeedbackForm.vue';
import MbButton from './library/MbButton.vue';

export default {
  components: {
    InfoOverlay,
    MbButton,
    FeedbackForm,
  },
  data() {
    return {
      showRestartDialog: false,
      showFeedbackForm: false,
    };
  },
  methods: {
    ...mapMutations(['DISPLAY_RULES']),
    ...mapActions(['goBack', 'goForward', 'resetPositions']),
    triggerGoBack() {
      if (!this.goBackAllowed) { return; }
      this.$amplitude.logEvent('GoBack');
      this.goBack();
    },
    triggerGoForward() {
      if (!this.goForwardAllowed) { return; }
      this.$amplitude.logEvent('GoForward');
      this.goForward();
    },
    restart() {
      this.showRestartDialog = false;
      this.$amplitude.logEvent('Restart');
      this.resetPositions(this.startPlayer);
    },
  },
  computed: {
    ...mapState(['startPlayer']),
    ...mapGetters(['goForwardAllowed', 'goBackAllowed']),
  },
  props: {
    collapse: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}
$large-screen: 1050px;

.nav-bar {
  background-color: $dark-sky-blue;
  color: $charcoal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .nav-bar__icon {
    height: 32px;
    width: 32px;
    border-radius: 32px;
    @include media-min($large-screen) {
      height: 64px;
      width: 64px;
      border-radius: 64px;
      margin: 12px;
    }
    margin-top: 12px;
  }
  .nav-bar__title {
    font-size: 24px;
    font-weight: 500;
    display: none;
    @include media-min($large-screen) {
      display: block;
    }
  }
  .nav-bar__group {
    margin: 12px;
    display: grid;
    grid-template-columns: 44px;
    @include media-min($large-screen) {
      grid-template-columns: 44px max-content;
    }
    grid-template-rows: 1fr;
    cursor: pointer;
    .nav-bar__item {
      display: contents;
      &:not([disabled=disabled]):hover {
        background-color: $charcoal;
        color: $dark-sky-blue;
      }
      &[disabled=disabled] {
        color: gray !important;
        cursor: auto;
      }
      .nav-bar-item__icon {
        padding: 8px 0;
        background-color: inherit;
        border-radius: 32px;
        @include media-min($large-screen) {
          border-radius: 32px 0 0 32px;
        }
      }
      .nav-bar-item__text {
        display: none;
        border-radius: 0 32px 32px 0;
        padding: 8px 12px;
        background-color: inherit;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        @include media-min($large-screen) {
          display: block;
        }
      }
    }
  }
}
</style>
