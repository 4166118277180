<template>
  <div class="board">
    <turn-your-screen v-if="portraitMode"/>
    <template v-else>
      <rules v-if="displayRules" @close="HIDE_RULES" @play="HIDE_RULES"/>
      <info-overlay v-if="showResultDialog" :title="winnerScreen.title">
        {{ winnerScreen.message }}
        <template v-slot:actions>
          <mb-button type="primary" @click="resetBoard(startPlayer)">Restart</mb-button>
          <mb-button type="secondary" @click="showResultDialog = false">See game</mb-button>
        </template>
      </info-overlay>
      <info-overlay v-if="showStartScreen" :title="startScreen.title">
        {{ startScreen.message }}
        <template v-slot:actions>
          <mb-button type="primary" @click="showStartScreen = false">Go</mb-button>
        </template>
      </info-overlay>
      <info-overlay v-if="!showResultDialog && connectionRequiresAttention"
        :title="connectionStatus" :try-again-func="retry">
        {{ connectionMessage }}
      </info-overlay>
      <nav-bar class="nav-bar" :collapse="!largeScreen"/>
      <div class="content">
        <div class="grid">
          <laser-emitter class="emitter"/>
          <laser-receiver
            :light-on="playerAlost" :primaryColor="playersColor.b" class="player-a"/>
          <laser-receiver
            :light-on="playerBlost" :primaryColor="playersColor.a" class="player-b"/>
          <template v-for="(line, i) in positions">
            <div
              v-for="(square, j) in line"
              :key="`square_(${i},${j})`"
              class="grid__square"
              :class="{
                'grid__square--highlighted': (i === selectedI) && (j === selectedJ),
                'grid__square--selectable': square.type === null,
                'wall__north': square.walls.includes('north'),
                'wall__east': square.walls.includes('east') || (j === map.width - 1),
                'wall__south': square.walls.includes('south') || (i === map.height - 1),
                'wall__west': square.walls.includes('west'),
              }"
              @click="selectSquare(i, j)">
              <transition name="destroyed">
                <component :is="square.type" v-if="square.type" :class="square.direction"
                  :color="playersColor[square.player]" :size="pieceSize"/>
              </transition>
            </div>
          </template>
          <div v-for="beamData, index in beamsWithData"
            :key="index" class="laser-beam" :style="beamData"></div>
        </div>
        <pieces-drawer
          class="pieces"
          :player="currentPlayer"
          :ready="showPiecesDrawer"
          :size="pieceSize"
          @change="updateSquare(selectedI, selectedJ, $event[0], $event[1])"/>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import {
  mapActions, mapGetters, mapState, mapMutations,
} from 'vuex';
import Stopper from '../components/Stopper.vue';
import Mirror from '../components/Mirror.vue';
import Spreader from '../components/Spreader.vue';
import SuperSpreader from '../components/SuperSpreader.vue';
import LaserEmitter from '../components/LaserEmitter.vue';
import LaserReceiver from '../components/LaserReceiver.vue';
import PiecesDrawer from '../components/PiecesDrawer.vue';
import NavBar from '../components/NavBar.vue';
import InfoOverlay from '../components/InfoOverlay.vue';
import TurnYourScreen from '../components/TurnYourScreen.vue';
import Rules from '../components/Rules.vue';
import MbButton from '../components/library/MbButton.vue';
import pieces from '../data/pieces.json';
import modes from '../helpers/playModes';
import status from '../helpers/connectionStatuses';
import otherPlayer from '../helpers/helpers';

export default {
  components: {
    Stopper,
    Mirror,
    Spreader,
    SuperSpreader,
    PiecesDrawer,
    NavBar,
    LaserEmitter,
    LaserReceiver,
    InfoOverlay,
    TurnYourScreen,
    MbButton,
    Rules,
  },
  data() {
    const directions = [{
      value: 'north',
      label: 'North',
    }, {
      value: 'east',
      label: 'East',
    }, {
      value: 'south',
      label: 'South',
    }, {
      value: 'west',
      label: 'West',
    }];
    return {
      options: [{
        value: 'stopper',
        label: 'Stopper',
        children: directions.map((d) => ({ ...d, parent: 'stopper' })),
      }, {
        value: 'mirror',
        label: 'Mirror',
        children: directions.map((d) => ({ ...d, parent: 'mirror' })),
      }, {
        value: 'spreader',
        label: 'Spreader',
        children: directions.map((d) => ({ ...d, parent: 'spreader' })),
      }, {
        value: 'super-spreader',
        label: 'Super spreader',
      }],
      selectedI: -1,
      selectedJ: -1,
      beams: [],
      showPiecesDrawer: false,
      showResultDialog: false,
      windowWidth: 0,
      windowHeight: 0,
      retry: null,
      showStartScreen: true,
      AIIsThinking: false,
    };
  },
  created() {
    if (this.playMode !== modes.ONLINE || this.localPlayer === 'a') {
      this.resetBoard();
    } else {
      this.$amplitude.logEvent('GameStart', { playMode: this.playMode });
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  computed: {
    ...mapState(['connectionStatus', 'connectionMessage', 'playMode', 'startPlayer', 'localPlayer', 'displayRules']),
    ...mapGetters(['positions', 'map', 'currentPlayer',
      'playersColor', 'connectionRequiresAttention', 'playersName', 'isHost']),
    beamsWithData() {
      return this.beams.map((beam) => this.calculateBeamPosition(
        beam[0][0], beam[0][1], beam[1][0], beam[1][1], this.squareSize,
      ));
    },
    playerAlost() {
      return this.beams.reduce((accb, b) => accb || (b.reduce((accbb, bb) => accbb
        || (bb[0] === this.map.players.a.i && bb[1] === this.map.players.a.j), false)), false);
    },
    playerBlost() {
      return this.beams.reduce((accb, b) => accb || (b.reduce((accbb, bb) => accbb
        || (bb[0] === this.map.players.b.i && bb[1] === this.map.players.b.j), false)), false);
    },
    winnerScreen() {
      if (this.playMode === modes.AI) {
        if (this.playerAlost) {
          return {
            title: 'Game Over',
            message: 'The A.I. wins!',
          };
        }
        return {
          title: 'Well done!',
          message: 'You outsmarted the A.I.!',
        };
      }
      if (this.draw) {
        return {
          title: 'Equality!',
          message: 'This is a draw.',
        };
      }
      return {
        title: 'Victory',
        message: `${this.playerBlost ? this.playersName.a : this.playersName.b} wins!`,
      };
    },
    startScreen() {
      const startMessage = {
        a: 'light up the yellow laser receiver on the right.',
        b: 'light up the blue laser receiver on the left.',
      };
      if (this.playMode === modes.AI) {
        if (this.startPlayer === 'a') {
          return {
            title: 'You start',
            message: `Your goal is to ${startMessage.a}`,
          };
        }
        return {
          title: 'The A.I. starts',
          message: `The A.I. will try to ${startMessage.b}`,
        };
      }
      if (this.playMode === modes.ONLINE) {
        if (this.localPlayer === this.startPlayer) {
          return {
            title: 'You start',
            message: `Your goal is to ${startMessage[this.localPlayer]}`,
          };
        }
        const op = otherPlayer(this.localPlayer);
        return {
          title: `${this.playersName[op]} starts`,
          message: `${this.playersName[op]}'s goal is to ${startMessage[op]}`,
        };
      }
      const pn = this.playersName[this.startPlayer];
      return {
        title: `${pn} starts`,
        message: `${pn}'s goal is to ${startMessage[this.startPlayer]}`,
      };
    },
    win() {
      return (this.playerAlost || this.playerBlost) && !this.draw;
    },
    draw() {
      return this.playerAlost && this.playerBlost;
    },
    largeScreen() {
      return this.windowWidth >= 1050;
    },
    pieceSize() {
      return this.largeScreen ? 'large' : 'default';
    },
    squareSize() {
      return this.largeScreen ? 75 : 50;
    },
    portraitMode() {
      return this.windowWidth < this.windowHeight;
    },
  },
  methods: {
    ...mapActions(['resetPositions', 'pushNewPositions']),
    ...mapMutations(['UPDATE_CONNECTION_STATUS', 'HIDE_RULES']),
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    resetBoard(currentStartPlayer = null) {
      this.showResultDialog = false;
      this.resetPositions(currentStartPlayer ? otherPlayer(currentStartPlayer) : null);
      this.$amplitude.logEvent('GameStart', { playMode: this.playMode });
      this.showStartScreen = true;
    },
    calculateBeamPosition(i1, j1, i2, j2, squareSize) {
      const top = (i1 <= i2 ? i1 : i2) * squareSize + squareSize / 2 - 1;
      const left = (j1 <= j2 ? j1 : j2) * squareSize + squareSize / 2 - 1;
      const width = Math.max(Math.abs(j1 - j2) * squareSize, 1);
      const height = Math.max(Math.abs(i1 - i2) * squareSize, 1);
      const maxWidth = (this.map.width * squareSize - left);
      const maxHeight = (this.map.height * squareSize - top);
      const isEmitter = ((i1 === this.map.emitter.i) && (j1 === this.map.emitter.j));
      return {
        top: `${isEmitter ? 0 : Math.max(top, 0)}px`,
        left: `${Math.max(left, 0)}px`,
        width: `${Math.min(width, maxWidth) + Math.min(left, 0)}px`,
        height: `${Math.min(height, maxHeight) + Math.min(top, 0)}px`,
      };
    },
    selectSquare(i, j) {
      if (this.positions[i][j].type) {
        return;
      }
      this.selectedI = i;
      this.selectedJ = j;
      this.showPiecesDrawer = true;
    },
    getCopyOfPositions() {
      return JSON.parse(JSON.stringify(this.positions));
    },
    isNewPieceValid(i, j, type, direction) {
      const piece = pieces[type][direction];
      if (i > 0) {
        const { type: t, direction: d } = this.positions[i - 1][j];
        if (t && (pieces[t][d].south === 'destroy' && piece.north === 'destroy')) {
          return [false, 'touch'];
        }
      } if (i < this.map.height - 1) {
        const { type: t, direction: d } = this.positions[i + 1][j];
        if (t && (pieces[t][d].north === 'destroy' && piece.south === 'destroy')) {
          return [false, 'touch'];
        }
      } if (j > 0) {
        const { type: t, direction: d } = this.positions[i][j - 1];
        if (t && (pieces[t][d].east === 'destroy' && piece.west === 'destroy')) {
          return [false, 'touch'];
        }
      } if (j < this.map.width - 1) {
        const { type: t, direction: d } = this.positions[i][j + 1];
        if (t && (pieces[t][d].west === 'destroy' && piece.east === 'destroy')) {
          return [false, 'touch'];
        }
      } if (i === (this.map.emitter.i + 1) && j === (this.map.emitter.j) && type === 'stopper'
        && direction === 'north') {
        return [false, 'blocking-emitter'];
      } if (i === (this.map.players.a.i) && j === (this.map.players.a.j + 1) && type === 'spreader'
        && direction === 'east') {
        return [false, 'blocking-receiver'];
      } if (i === (this.map.players.b.i) && j === (this.map.players.b.j - 1) && type === 'spreader'
        && direction === 'west') {
        return [false, 'blocking-receiver'];
      }
      return [true, null];
    },
    updateSquare(i, j, type, direction = 'north') {
      const [valid, message] = this.isNewPieceValid(i, j, type, direction);
      this.$amplitude.logEvent('MakeMove', {
        i, j, type, direction,
      });
      if (!valid) {
        this.$amplitude.logEvent('InvalidMove', {
          message,
        });
        const messages = {
          touch: 'You can\'t place this piece here: non-reflecting sides of pieces must not touch each other.',
          'blocking-emitter': 'You can\'t place this piece here: it is not allowed to block the laser emitter.',
          'blocking-receiver': 'You can\'t place this piece here: it is not allowed to block the laser receiver.',
        };
        this.$message({
          showClose: true,
          message: messages[message],
          type: 'error',
          duration: 5000,
        });
        return;
      }
      const player = this.currentPlayer;
      const newPositions = this.getCopyOfPositions();
      newPositions[i][j].type = type;
      newPositions[i][j].direction = direction;
      newPositions[i][j].player = player;
      this.pushNewPositions(newPositions);
      this.selectedI = -1;
      this.selectedJ = -1;
      this.showPiecesDrawer = false;
    },
    destroyPiece(i, j) {
      this.positions[i][j].type = null;
      this.positions[i][j].direction = null;
      this.fire();
    },
    fireSubBeam(startI, startJ, from, alreadyVisited) {
      const translation = {
        north: 'south',
        east: 'west',
        south: 'north',
        west: 'east',
      };
      const beam = [[startI, startJ]];
      let i = startI;
      let j = startJ;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        switch (from) {
          case 'north':
            i += 1;
            break;
          case 'east':
            j -= 1;
            break;
          case 'south':
            i -= 1;
            break;
          case 'west':
            j += 1;
            break;
          default:
            return false;
        }
        if ((i === -1) || (i === this.map.height) || (j === -1) || (j === this.map.width)) {
          beam.push([i, j]);
          return [beam];
        }
        const { type } = this.positions[i][j];
        if (type) {
          let { direction } = this.positions[i][j];
          if (type === 'super-spreader') {
            direction = 'any';
          }
          if (pieces[type][direction][from] === 'destroy') {
            this.destroyPiece(i, j);
            return false;
          }
          if (alreadyVisited.has(`${i},${j}`)) {
            beam.push([i, j]);
            return [beam];
          }
          beam.push([i, j]);
          const ii = Number(i);
          const jj = Number(j);
          const beams = [beam];
          alreadyVisited.add(`${ii},${jj}`);
          pieces[type][direction][from].forEach(
            (to) => {
              if (!this.positions[ii][jj].walls.includes(to)) {
                let subBeams = this.fireSubBeam(ii, jj, translation[to], alreadyVisited);
                if (!subBeams) {
                  subBeams = [false];
                }
                subBeams.forEach((b) => {
                  beams.push(b);
                });
              }
            },
          );
          if (beams.includes(false)) {
            return false;
          }
          return beams;
        }
        if (this.positions[i][j].walls.includes(translation[from])) {
          switch (from) {
            case 'north':
              beam.push([i + 0.5, j]);
              break;
            case 'east':
              beam.push([i, j - 0.5]);
              break;
            case 'south':
              beam.push([i - 0.5, j]);
              break;
            case 'west':
              beam.push([i, j + 0.5]);
              break;
            default:
              return false;
          }
          return [beam];
        }
      }
    },
    fire() {
      this.beams = [];
      const alreadyVisited = new Set();
      const beams = this.fireSubBeam(this.map.emitter.i, this.map.emitter.j, 'north', alreadyVisited);
      if (beams) {
        this.beams = beams;
      }
    },
    playAITurn() {
      if (this.AIIsThinking) {
        return;
      }
      if (this.playMode === modes.AI && this.currentPlayer === 'b') {
        this.AIIsThinking = true;
        axios.post('https://invghpu4h8.execute-api.eu-west-3.amazonaws.com/mirror-battle-ai', {
          positions: this.positions,
          player: 'b',
        }).then((response) => {
          this.UPDATE_CONNECTION_STATUS({
            status: status.SUCCESS,
            message: 'Connected to A.I.',
          });
          const [i, j, t, d] = response.data;
          if (!this.playerBlost) {
            this.updateSquare(i, j, t, d);
          }
          this.AIIsThinking = false;
        }).catch((error) => {
          this.UPDATE_CONNECTION_STATUS({
            status: status.ERROR,
            message: error,
          });
          this.retry = this.playAITurn;
          this.AIIsThinking = false;
        });
      }
    },
  },
  watch: {
    positions() {
      this.$nextTick(this.fire);
    },
    playerAlost() {
      const v = this;
      if (!this.draw) {
        this.$amplitude.logEvent('GameEnd', { result: 'b' });
      }
      setTimeout(() => {
        v.showResultDialog = v.playerAlost;
      }, 1000);
    },
    playerBlost() {
      const v = this;
      if (!this.draw) {
        this.$amplitude.logEvent('GameEnd', { result: 'a' });
      }
      setTimeout(() => {
        v.showResultDialog = v.playerBlost;
      }, 1000);
    },
    currentPlayer() {
      this.playAITurn();
    },
    draw() {
      this.$amplitude.logEvent('GameEnd', { result: 'equality' });
    },
    connectionStatus() {
      if (this.playMode === modes.ONLINE || this.playMode === modes.AI) {
        this.$amplitude.logEvent('ConnectionStatusChanged', {
          status: this.connectionStatus,
          playMode: this.playMode,
          message: this.connectionMessage,
        });
      }
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

$large-screen: 1050px;

.board {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .nav-bar {
    flex: 1 0 max(64px, calc(44px + env(safe-area-inset-left)));
    @include media-min($large-screen) {
      flex: 1 0 160px;
    }
    height: 100vh;
    padding-left: calc(env(safe-area-inset-left) - 20px);
  }
  .content {
    flex: 1 1 100%;
    overflow: scroll;
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .grid {
      display: grid;
      grid-template-rows: repeat(7, 50px);
      grid-template-columns: repeat(11, 50px);
      width: calc(11 * 50px);
      height: calc(7 * 50px);
      margin: 0 20px;
      @include media-min($large-screen) {
        grid-template-rows: repeat(7, 75px);
        grid-template-columns: repeat(11, 75px);
        min-width: calc(11 * 75px);
        min-height: calc(7 * 75px);
      }
      border-top: 1px solid $dark-sky-blue;
      border-left: 1px solid $dark-sky-blue;
      position: relative;
      .emitter {
        position: absolute;
        top: -50px;
        left: calc(50% - 25px);
        z-index: 20;
        height: 50px;
        width: 50px;
      }
      .player-a {
        position: absolute;
        top: calc(50% - 25px);
        left: -15px;
        z-index: 20;
        height: 50px;
        width: 15px;
        transform: rotateY(180deg);
      }
      .player-b {
        position: absolute;
        top: calc(50% - 25px);
        right: -15px;
        z-index: 20;
        height: 50px;
        width: 15px;
      }
      .grid__square {
        border-right: 0px dashed $dark-sky-blue;
        border-bottom: 0px dashed $dark-sky-blue;
        position: relative;
        z-index: 2;
        &:nth-of-type(odd) {
          background-color: rgba(134, 189, 216, 0.1);
        }
        .north {
          transform: rotate(0deg);
        }
        &.wall__north {
          border-top: 1px solid $dark-sky-blue;
        }
        .east {
          transform: rotate(90deg);
        }
        &.wall__east {
          border-right: 1px solid $dark-sky-blue;
        }
        .south {
          transform: rotate(180deg);
        }
        &.wall__south {
          border-bottom: 1px solid $dark-sky-blue;
        }
        .west {
          transform: rotate(270deg);
        }
        &.wall__west {
          border-left: 1px solid $dark-sky-blue;
        }
        &.grid__square--selectable {
          cursor: pointer;
        }
        &.grid__square--highlighted {
          z-index: 0;
          background-color: gold;
          animation-name: flashing;
          animation-duration: 0.5s;
          animation-iteration-count:infinite;
        }
        .destroyed-leave-active {
          animation: destroying 3s;
        }
      }
      .laser-beam {
        background-color: red;
        position: absolute;
        animation-name: perturbations;
        animation-duration: 2s;
        animation-iteration-count:infinite;
      }
    }
    .pieces {
      height: calc(7 * 50px);
      width: 115px;
      @include media-min($large-screen) {
        height: calc(7 * 75px);
        width: 150px;
      }
    }
  }
}

@keyframes perturbations {
  from {
    box-shadow: none;
  }
  50% {
    box-shadow: 0px 0px 1px 0px rgba(255,0,0,0.8);
    transform: translate(1px, 1px);
  }
  to {
    box-shadow: none;
  }
}

@keyframes flashing {
  from {
    background-color: rgba(255,215,0,1.0);
  }
  50% {
    background-color: rgba(255,215,0,0.8);
  }
  to {
    background-color: rgba(255,215,0,1.0);
  }
}

@keyframes destroying {
  from {
    opacity: 1.0;
    filter: blur(1px);
    color: red;
  }
  20% {
    opacity: 1.0;
    filter: blur(2px);
    color: red;
  }
  40% {
    opacity: 0.8;
    filter: blur(4px);
    color: red;
  }
  to {
    opacity: 0.2;
    filter: blur(8px);
    color: red;
  }
}

.flip-enter {
  transform: rotateY(180deg);
}
.flip-leave {
  transform: rotateY(0deg);
}
.flip-enter-active {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  transition: transform 10s;
}
.flip-leave-active {
  transition: transform 10s;
}
.flip-enter-to {
  transform: rotateY(0);
}
.flip-leave-to {
  transform: rotateY(180deg);
}

</style>
