<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="50" version="1.1">
    <rect width="15" height="50" stroke="#2F4858" stroke-width="1" fill="white"/>
    <rect y="5" width="4" height="40" stroke="#2F4858" stroke-width="0" :fill="primaryColor"/>
    <rect x="8" y="20" width="2" height="25" stroke="#2F4858" stroke-width="0" fill="#2F4858"/>
    <circle cx="9" cy="10" r="3" stroke="#2F4858" stroke-width="1" :fill="effectiveLightColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: '#00A99D',
    },
    lightColor: {
      type: String,
      default: 'red',
    },
    lightOn: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    effectiveLightColor() {
      return this.lightOn ? this.lightColor : 'darkgray';
    },
  },
};
</script>
